<template>
    <div class="bgcontent">
        <div class="header">
            <div class="back" @click="handleTitleBack">
                <img class="backimg" :src="require('@/assets/images/index/back.png')"/>
            </div>
            <div class="title">
                资源转换器
            </div>
        </div>
        <div class="list">
            <div class="card" v-for="(item, index) in resourceList" :key="index">
                <div class="top">
                    <div class="left">
                        <img class="iconimg" :src="getImg(item.package_zh_name)"/>
                        <div>
                            <div class="title">{{item.package_zh_name}}资源转换器</div>
                            <div class="everyday"></div>
                            <div class="txt">增加活跃度 {{parseFloat(item.active).toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <!--<div class="txt">收益率:{{toSecond(parseFloat(item.profit)*100)}}%</div>-->
                        <div class="txt" v-if="item.limit==999">持有无限制</div>
                        <div class="txt" v-else>最多持有：{{item.limit}}</div>
                    </div>
                </div>
                <div class="middle">
                    <div class="txt">
                        总共释放：{{toSecond(item.totalProfit)}}ATT
                    </div>
                    <div class="txt">
                        锁仓周期(天)：{{item.day}}
                    </div>
                </div>
                <div class="end">
                    <div class="txt">
                        <div>
                            所需:
                        </div>
                        <div>
                            {{toSecond(item.price)}}/ATT
                        </div>
                    </div>
                    <!--<div class="txt">-->
                        <!--<div>-->
                            <!--手续费消耗:-->
                        <!--</div>-->
                        <!--<div>-->
                            <!--{{(parseFloat(item.price)*0.03).toFixed(4)}}EPT-->
                        <!--</div>-->
                    <!--</div>-->
                    <div class="right">

                        <div class="btn" @click="toSubmitDui(item)">
                            兑 换
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-dialog v-model="showpwd" title="资源转换器支付" @cancel="cancel"  @confirm="confirm" show-cancel-button>
            <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
        </van-dialog>
    </div>
</template>

<script>
    import {getResourceList,canResource,getWalletInfo} from '@/request/api'
    import { Toast } from 'vant';
    import $ from 'jquery'

    export default {
        name: 'Transfor',
        components: {},
        data() {
            return {
                resourceList: [],
                address: '',
                showpwd: false,
                password: '',
                userInfo:{},
                chooseInfo:{},
                walletList:[],
            };
        },
        activated() {
            this.address = sessionStorage.getItem("address");
            this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
            if(this.address){
                this.getResourceListData();
                this.getWalletList();
            }
        },
        methods: {
            getWalletList() {
                let _this = this;
                const params = {
                    address: this.address
                }
                getWalletInfo(params).then((res)=> {
                    $.each(res.data.list,function (i,ele) {
                        _this.walletList[ele.coin_symbol] = parseFloat(ele.cloud_balance).toFixed(4);
                    })
                    console.log(_this.walletList)
                })
            },
            toSubmitDui(item){
                this.password = '';
                if(item.shenCount<1){
                    Toast('该类型转换器参与次数已达上限');
                    return
                }
                console.log(this.walletList['ATT']);
                if(parseFloat(item.price)>parseFloat(this.walletList['ATT'])){
                    Toast('ATT可用余额不足');
                    return
                }
                // if(parseFloat(item.price)*0.1>parseFloat(this.walletList['EPT'])){
                //     Toast('EPT可用余额不足');
                //     return
                // }
                // this.showpwd = true;
                this.chooseInfo = item;
                console.log(this.chooseInfo)
                this.confirm();
            },
            cancel() {
                this.showpwd = false;
                this.password = '';
                this.chooseInfo={};
            },
            confirm () {
                let _this = this;
                if(!this.address){
                    Toast('请使用币安智能链链接');
                    return
                }
              /*  if(this.address&&this.userInfo.has_paypwd!=1){
                    Toast('请先完善资料,设置安全密码');
                    setTimeout(function () {
                        _this.$router.push({
                            path:'/home/mine'
                        })
                    })
                    return
                }*/
                /*if(this.password.length === 0){
                    Toast('请输入安全密码')
                    return;
                }*/
                if(!this.chooseInfo.resource_package_id){
                    Toast('选择兑换的资源转换器');
                    return;
                }
                if(this.chooseInfo.shenCount<1){
                    Toast('该类型转换器兑换次数已达上限');
                    return
                }
                if(parseFloat(this.chooseInfo.price)>parseFloat(this.walletList['ATT'])){
                    Toast('ATT可用余额不足');
                    return
                }
             /*   if(parseFloat(this.chooseInfo.price)*0.1>parseFloat(this.walletList['EPT'])){
                    Toast('EPT可用余额不足');
                    return
                }*/
                const params = {
                    address:this.address,
                    pay_code:this.password,
                    id:this.chooseInfo.resource_package_id,
                }
                canResource(params).then(res=> {
                    _this.password = '';
                    if(res.code==200){
                        Toast('兑换成功')
                    }
                    else if(res.code==100000){
                        Toast('资源转换器兑换次数不足')
                    }
                    else if(res.code==100001){
                        Toast('安全密码错误')
                    }
                    else if(res.code==100002){
                        Toast('ATT可用余额不足')
                    }
                    else{
                        Toast('兑换失败')
                    }
                    // else if(res.code==100003){
                    //     Toast('EPT可用余额不足')
                    // }

                    setTimeout(function () {
                        location.reload()
                    },3000)
                }).catch(err=> {
                    Toast('兑换失败')
                    setTimeout(function () {
                        location.reload()
                    },3000)
                })
            },
            toSecond(value){
                return parseFloat(value).toFixed(2)
            },
            getImg(type) {
                let imgurl = ''
                if (type === "体验型") {
                    imgurl = require('@/assets/images/index/micro.png')
                }
                else if (type === "微型") {
                    imgurl = require('@/assets/images/index/super.png')
                }
                else if (type === "起点型") {
                    imgurl = require('@/assets/images/index/small.png')
                } else if (type === '中型') {
                    imgurl = require('@/assets/images/index/middle.png')
                } else if (type === '大型') {
                    imgurl = require('@/assets/images/index/big.png')
                } else if (type === '巨型') {
                    imgurl = require('@/assets/images/index/huge.png')
                } else if (type === '超级') {
                    imgurl = require('@/assets/images/index/chaoji.png')
                } else if (type === '云级') {
                    imgurl = require('@/assets/images/index/a_2.png')
                } else if (type === '小型') {
                    imgurl = require('@/assets/images/index/a_1.png')
                }
                return imgurl
            },
            handleTitleBack() {
                this.$router.back()
            },
            getResourceListData() {
                const params = {
                    address: this.address
                }
                getResourceList(params).then(res => {
                    this.resourceList = res.data
                })
            }
        },
    };
</script>

<style lang="scss" scoped>
    .bgcontent {
        min-height: 100vh;
        padding: 10px 32px;
        background: url('../../assets/images/transfer_bg.png');
        background-size: 100% 100%;
        .header {
            display: flex;
            justify-content: center;
            .back {
                position: relative;
                left: -220px;
                .backimg {
                    width: 48px;
                    height: 48px;

                }
            }

            .title {
                opacity: 1;
                color: rgba(13, 25, 18, 1);
                font-size: 36px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
            }
        }
        .list {
            margin-top: 32px;
            .card {
                padding: 18px 24px;
                width: 670px;
                height: 398px;
                border-radius: 16px;
                opacity: 1;
                background: linear-gradient(180deg, rgba(238, 221, 154, 1) 0%, rgba(247, 237, 197, 1) 52%, rgba(228, 212, 147, 1) 100%);
                box-shadow: inset -4px -4px 8px 0 rgba(239, 219, 139, 1), -4px -4px 8px 0 rgba(167, 148, 91, 1);
                margin-bottom: 32px;
            }
            .top {
                display: flex;
                justify-content: space-between;
                .left {
                    display: flex;

                    .iconimg {
                        width: 156px;
                        height: 156px;
                    }
                    .title {
                        opacity: 1;
                        color: rgba(53, 44, 11, 1);
                        font-size: 36px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        line-height: 48px;
                    }
                    .everyday {
                        color: rgba(53, 44, 11, 1);
                        font-size: 44px;
                        font-weight: 500;
                        font-family: "DIN";
                        text-align: center;
                    }
                    .txt{
                        margin-top:32px
                    }
                }
                .txt {
                    color: rgba(101, 84, 21, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;

                }
                .right {
                    margin-top: 80px
                }
            }
            .middle {
                margin-top: 20px;
                width: 622px;
                height: 56px;
                border-radius: 16px;
                background: rgba(53, 44, 11, 0.05);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;
                .txt {
                    color: rgba(151, 125, 30, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                }
            }
            .end {
                margin-top: 46px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .right {
                    display: flex;
                    align-items: center;
                    .btn {
                        margin-left: 20px;
                        width: 200px;
                        height: 88px;
                        border-radius: 16px;
                        opacity: 1;
                        background: rgba(39, 204, 127, 1);
                        box-shadow: inset 0 8px 16px 0 rgba(206, 255, 232, 0.5);
                        color: rgba(53, 44, 11, 1);
                        font-size: 36px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 88px;
                    }
                }
            }
        }
    }
</style>